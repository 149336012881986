.react-draft-root {
  margin: 20px;
}

.react-draft-toolbar {
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}

.react-draft-editor {
  border: 1px solid #e0e0e0;
  padding: 5px;
  height: calc(100vh - 400px);
}

.react-draft-button {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.rdw-block-dropdown {
  width:130px;
}
